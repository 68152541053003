import React from 'react'
import polygon from '../assets/polygon.png'
import mob1 from '../assets/mob1.png'
import mob2 from '../assets/mob2.png'
import mob3 from '../assets/mob3.png'
import mob4 from '../assets/mob4.png'
import mob5 from '../assets/mob5.png'
import { Fade } from "react-awesome-reveal";


function AppFeature() {


    return (
        <div className='relative h-full w-full font-poppins'>

            <h1 className='text-primary bg-secondary font-bold text-4xl py-8 text-center'>App Features</h1>

            <div className='h-full w-full flex gap-16 md:gap-4 flex-col justify-center items-center px-12 py-16'>

                <Fade triggerOnce direction="left">
                    <div
                        className='flex flex-col md:flex-row gap-4 md:gap-8 transition-all duration-500 w-[80%] lg:w-full items-stretch h-full m-auto'>

                        <div className='w-full flex justify-center items-center flex-col'>
                            <h1 className='w-full text-center md:text-right font-semibold text-xl'>The Marketplace</h1>
                            <p className='text center md:text-right mt-4'>
                                List, view and search items available on the marketplace.
                                Categorize or search your fashion preferences and
                                view the latest trends and stories.
                            </p>
                        </div>

                        <div className='relative h-full w-full flex justify-center items-center'>
                            <img src={polygon} alt='polygon' className='absolute h-72' />
                            <img src={mob2} alt='mob2' className='h-96 float-end relative z-10 -right-6' />
                            <img src={mob1} alt='mob1' className='h-96 float-start relative z-20 -left-6' />
                        </div>

                    </div>
                </Fade>


                <Fade triggerOnce direction="right">
                    <div
                        className='flex flex-col md:flex-row-reverse gap-4 transition-all duration-500 md:gap-8 items-stretch h-full w-[80%] lg:w-full m-auto'>

                        <div className='w-full flex justify-center items-center flex-col'>
                            <h1 className='w-full text center md:text-left font-semibold text-xl'>Chat Feature</h1>
                            <p className='text center md:text-left mt-4'>
                                Our app provides you with a chat feature to negotiate, schedule orders and direct message.
                            </p>
                        </div>

                        <div className='relative h-full w-full flex justify-center items-center'>
                            <img src={polygon} alt='polygon' className='absolute h-72' />
                            <img src={mob3} alt='mob2' className='h-96 float-end relative z-10 -right-7 md:-right-6' />
                            <img src={mob4} alt='mob1' className='h-96 float-start relative z-20 -left-7 md:-left-6' />
                        </div>

                    </div>
                </Fade>



                <Fade triggerOnce direction="left">
                    <div
                        className='flex flex-col md:flex-row gap-4 md:gap-8 transition-all duration-500  items-stretch h-full w-[80%] lg:w-full m-auto'>

                        <div className='w-full flex justify-center items-center flex-col'>
                            <h1 className='w-full text center md:text-right font-semibold text-xl'>Barcode</h1>
                            <p className='text center md:text-right mt-4'>
                                Once a purchased is made, users will scan a barcode to verify the exchange has been processed.
                            </p>
                        </div>

                        <div className='relative h-full w-full flex justify-center items-center'>
                            <img src={polygon} alt='polygon' className='absolute h-72' />
                            <img src={mob5} alt='mob2' className='h-96 float-end relative z-10' />
                        </div>

                    </div>
                </Fade>


                <div className="hidden md:block gradient"></div>
                <div className="block md:hidden gradient-mob"></div>

            </div>
        </div >
    )
}

export default AppFeature