import * as React from 'react'
import { cva } from 'class-variance-authority'

import { cn } from '../../lib/utils'

var baseStyles = 'inline-flex font-poppins font-bold hover:cursor-pointer active:scale-90 transition-all  items-center justify-center rounded-md duration-300 ease-in-out'

const buttonVariants = cva(baseStyles,
    {
        variants: {
            variant: {
                default:
                    'bg-background text-primary shadow-md'
            },
            size: {
                default: 'py-1 px-6',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)



const Button = React.forwardRef(
    ({ className, children, text, href, variant, size, ...props }, ref) => {
        return (
            <button
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
            >
                {text}
            </button>
        )
    }
)
Button.displayName = 'Button'

export { Button, buttonVariants }