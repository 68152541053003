import React from 'react'
import icon from '../assets/icon.png'
import email from '../assets/icons/email.png'
import insta from '../assets/icons/insta.png'
import tiktok from '../assets/icons/tiktok.png'

function Contact() {
    return (
        <div id="contact" className='flex font-poppins flex-col md:flex-row gap-12 md:gap-2 justify-center items-center p-12'>


            <img src={icon} alt='icon' className='h-36' />


            <div className='w-full flex justify-center md:justify-end items-center md:items-end flex-col gap-6'>


                <h1 className='text-5xl w-full text-center md:text-right font-semibold'>Contact Us</h1>

                <div className='flex text-2xl font-semibold justify-center md:justify-end w-full items-center gap-2'>
                    <img src={email} className='size-4' />
                    <h1 className='text-sm lg:text-xl'>customersupport@eclosetapp.com</h1>
                </div>

                <h1 className='text-sm lg:text-xl'>We look forward to hearing from you!</h1>

                <div className='w-full flex justify-center md:justify-end gap-2 flex-col'>
                    <h1 className='font-semibold w-full text-center md:text-right'>FOLLOW US</h1>
                    <div className='flex justify-center md:justify-end gap-4'>
                        <a href='https://www.instagram.com/ecloset.app/' target='__blank'>
                            <img src={insta} className='size-6' />
                        </a>
                        <a href='https://www.tiktok.com/' target='__blank'>
                            <img src={tiktok} className='size-6' />
                        </a>
                    </div>
                </div>


            </div>


        </div>
    )
}

export default Contact