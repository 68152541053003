import React from 'react'
import Header from '../components/Header'
import AppFeature from '../components/AppFeature'
import AboutUs from '../components/AboutUs'
import Contact from '../components/Contact'   

function Home() {
    return (
        <div className='h-full w-full'>
            <Header />
            <AboutUs />
            <AppFeature />
            <Contact />
        </div>
    )
}

export default Home